.listView{
    margin-left: 20px;
}


.decisionText{
    font-weight: 500;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    
}

.decisionApprove{
    background-color: #DAFBE8;
    color: #1BA858;
}

.decisionReject{
    background-color: #fdbbd2;
    color: #db044f;
}

.warningText {
    font-weight: 500;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}

.warningNone {
    background-color: #DAFBE8;
    color: #1BA858;
}

.warningColored {
    background-color: #ffedb7;
    color: #FD7F20;
    padding: 2px 10px 5px 0px;
}


@media (max-width: 400px) {
    .listView{
        margin-left: 0;
    }
  }
